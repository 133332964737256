/* External dependencies */
import { Div, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import Button from '../../ui/button';

interface DropDownProps {
  items: string[];
  withoutTranslation?: boolean;
  onClick: (item: string) => void;
}

export default function DropdownItems({
  items,
  onClick,
  withoutTranslation = false,
}: DropDownProps) {
  return (
    <Div bg="table_cell_background" overflow="hidden" h="100%">
      {items?.map((item, index) => (
        <Button
          key={index}
          width="100%"
          typeColor="default"
          onClick={() => onClick(item)}
          textTransform="uppercase"
          textSize="12px"
          textColor="black"
          style={{
            borderTop: '1px solid #E4E9F2',
            height: '40px',
            fontWeight: '600',
            justifyContent: 'left',
            lineHeight: '16px',
            textAlign: 'center',
          }}
          rounded="0"
        >
          {!withoutTranslation ? (
            <Text textSize="13px">
              <Trans>{item}</Trans>
            </Text>
          ) : (
            <Text textSize="13px">{item}</Text>
          )}
        </Button>
      ))}
    </Div>
  );
}
