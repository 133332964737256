// External dependencies
import { Div, Text } from 'atomize';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app/redux/store';
import {
  ListUserActionsFilter,
  listUserActions,
} from '@entities/user-actions/redux/listUser-actions-redux/actions';
import { ActionType, UserActionStatus } from '@entities/user-actions/types';
import CustomDropdown from '@shared/ui/custom-dropdown';
import Search from '@shared/ui/search/Search';
import Tabs from '@shared/ui/tab/Tabs';
import {
  userActionStatusTabs,
  userActionTypesForMeducationManager,
} from '@entities/user-actions/utils/helpers';

export default function MeducationManagerControlBarActions() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentPage, loading, searchString, total, filter } = useSelector(
    (state: RootState) => state.listUserActions,
  );
  const searchPlaceholderText = t('placeholderUserActionText');

  const meducationManagerFilter: ListUserActionsFilter = {
    ...filter,
    actionType: ActionType.EXCHANGEPOINTS,
  };

  useEffect(() => {
    dispatch(listUserActions('', currentPage, meducationManagerFilter));
  }, []);

  function onSearch(e) {
    dispatch(
      listUserActions(e.target.value, currentPage, meducationManagerFilter),
    );
  }

  function resetSearchField() {
    onSearch({
      target: { value: '' },
    });
  }

  function onSelectTab(status: UserActionStatus) {
    dispatch(
      listUserActions(searchString, currentPage, {
        ...meducationManagerFilter,
        status: status,
      }),
    );
  }

  function onSelectUserActionType(actionType: ActionType) {
    dispatch(
      listUserActions(searchString, currentPage, {
        ...meducationManagerFilter,
        actionType: actionType,
      }),
    );
  }

  return (
    <>
      <Text textSize="subtitle" textWeight="700" m={{ b: '20px' }}>
        <Trans>userActions</Trans> ({loading ? '...' : total})
      </Text>
      <Div m={{ b: '20px' }} d="flex" align="center" style={{ gap: '30px' }}>
        <Div>
          <Search
            onChange={onSearch}
            placeholder={searchPlaceholderText}
            onClear={resetSearchField}
            value={searchString ? searchString : ''}
          />
        </Div>
        <Div>
          <CustomDropdown
            disabled={true}
            onSelectAction={onSelectUserActionType}
            items={userActionTypesForMeducationManager}
            name={filter?.actionType || 'chooseActionType'}
          />
        </Div>
        <Div>
          <Tabs
            items={userActionStatusTabs}
            value={filter?.status}
            onClick={onSelectTab}
            inputName="filter"
          />
        </Div>
      </Div>
    </>
  );
}
