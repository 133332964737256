/* Local dependencies */
import { CourseStatus } from '../../../components/courses/types';
import { UserActionStatus } from '../../user-actions/types';
import { UserStatus } from './types';

export function getStatusColor(statusInput: any) {
  switch (statusInput) {
    case UserStatus.APPROVED:
      return 'approved_text_color';

    case CourseStatus.ENABLED:
      return 'approved_text_color';

    case UserStatus.REJECTED:
      return 'blocked_text_color';

    case UserStatus.REJECTED:
      return 'blocked_text_color';

    case UserActionStatus.CANCELED:
      return 'blocked_text_color';

    case CourseStatus.DISABLED:
      return 'blocked_text_color';

    case UserStatus.INCOMPLETE:
      return 'incomplate_text_color';

    case UserStatus.IN_REVIEW:
      return 'in_review_text_color';

    default:
      return statusInput;
  }
}
