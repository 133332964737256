// External depentensies
import React from 'react';

// Local dependensieseact
import { IconProps } from './types';

export default function DefaultAvatarIcon({
  width = 74,
  height = 74,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="37" cy="37" r="37" fill="#F0F2F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.3327 25.3333C46.3327 30.4807 42.1467 34.6667 36.9993 34.6667C31.852 34.6667 27.666 30.4807 27.666 25.3333C27.666 20.186 31.852 16 36.9993 16C42.1467 16 46.3327 20.186 46.3327 25.3333ZM53.3327 55.6667C53.3327 56.9547 52.2897 58 50.9993 58H22.9993C21.709 58 20.666 56.9547 20.666 55.6667C20.666 46.66 27.995 39.3333 36.9993 39.3333C46.0037 39.3333 53.3327 46.66 53.3327 55.6667Z"
        fill="#D3DDF0"
      />
      <mask
        id="mask0_11389_61672"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="20"
        y="16"
        width="34"
        height="42"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.3327 25.3333C46.3327 30.4807 42.1467 34.6667 36.9993 34.6667C31.852 34.6667 27.666 30.4807 27.666 25.3333C27.666 20.186 31.852 16 36.9993 16C42.1467 16 46.3327 20.186 46.3327 25.3333ZM53.3327 55.6667C53.3327 56.9547 52.2897 58 50.9993 58H22.9993C21.709 58 20.666 56.9547 20.666 55.6667C20.666 46.66 27.995 39.3333 36.9993 39.3333C46.0037 39.3333 53.3327 46.66 53.3327 55.6667Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_11389_61672)">
        <rect x="9" y="9" width="56" height="56" fill="#D3DDF0" />
      </g>
    </svg>
  );
}
