/* External dependencies */
import { Div } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import Button from '../button';
import './style.scss';

interface TabProps {
  inputName: string;
  items: any[];
  value?: any;
  onClick?: any;
}

export default function Tabs(props: TabProps) {
  const { items, onClick, value } = props;
  const isActive = (item) => item === value;
  const { t } = useI18next();

  const getTabClassName = (value: string): string => {
    const activeTab = isActive(value) ? `tab--active` : '';

    return activeTab;
  };

  return (
    <Div
      d="flex"
      overflow="auto"
      style={{ whiteSpace: 'nowrap' }}
      rounded="5px"
    >
      {items.map((item, index) => (
        <Button
          tab
          typeColor={isActive(item.label) ? 'primary' : 'default'}
          key={index}
          rounded="0px"
          onClick={() => onClick(item.label)}
          className={`filter-item  ${getTabClassName(item.label)}`}
          textColor={isActive(item.label) ? '' : 'backgroundTextColor'}
        >
          {t(item.label)}
        </Button>
      ))}
    </Div>
  );
}
