/* External dependencies */
import { Div, Modal, Icon, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';

interface ImageZoomPopupProps {
  image: string;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  zoomScale?: any;
}

export default function ImageZoomPopup({
  image,
  isOpen,
  onClose,
  title,
  zoomScale = 3,
}: ImageZoomPopupProps) {
  const headerSize = '18px';

  const [offset, setOffset] = useState({
    mouseX: null,
    mouseY: null,
  });
  const [zoom, setMouseInOut] = useState(false);
  const imageRef = useRef(null);

  const innerDivStyle = {
    backgroundImage: `url('${image}')`,
  };

  function handleMouseOver() {
    setMouseInOut(true);
  }

  function handleMouseOut() {
    setMouseInOut(false);
  }

  function handleMouseMovement(e) {
    const {
      left: offsetLeft,
      top: offsetTop,
      width,
      height,
    } = imageRef.current.getBoundingClientRect();

    const x = ((e.clientX - offsetLeft) / parseInt(width, 10)) * 100;
    const y = ((e.clientY - offsetTop) / parseInt(height, 10)) * 100;

    setOffset({ ...offset, mouseX: x, mouseY: y });
  }

  const transform = {
    transformOrigin: `${offset.mouseX}% ${offset.mouseY}%`,
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return (
    <Modal
      align="center"
      onClose={onClose}
      rounded="sm"
      w="auto"
      maxW="700px"
      p="10px"
      isOpen={isOpen}
      className="popup"
      m="20px"
    >
      <Div d="flex" justify="end" p={{ y: '5px' }}>
        <Div
          bg="gray"
          hoverBg="gray_bg_hover"
          d="flex"
          align="center"
          justify="center"
          h="27px"
          w="27px"
          rounded="50%"
          cursor="pointer"
          onClick={onClose}
        >
          <Icon name="Cross" size="25px" color="grayTextInCard" />
        </Div>
      </Div>
      {title && (
        <Text
          tag="h1"
          textAlign="center"
          textSize={headerSize}
          textColor="grey"
          p={{ x: '1.5rem', y: '1rem' }}
        >
          <Trans>{title}</Trans>
        </Text>
      )}

      <div
        className="zoomOuter"
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        onMouseMove={handleMouseMovement}
        ref={imageRef}
      >
        <div className="zoomInnerWrapper">
          <div
            style={{
              ...innerDivStyle,
              ...transform,
              transform: zoom ? `scale(${zoomScale})` : 'scale(1.0)',
            }}
            className="zoomInner"
          ></div>
        </div>
      </div>
    </Modal>
  );
}
