/* External dependencies */
import { Col, Div, Row, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import { getStatusColor } from '../../../entities/users/lib/renderStatusColor';
import NotFound from '../notFound/NotFound';
import Pagination from '../pagination/Pagination';
import Spinner from '../spinner/Spinner';
import './table.scss';

export enum RowSelectionType {
  MULTI = 'MULTI',
  NONE = 'NONE',
  SINGLE = 'SINGLE',
  NUMBER = 'NUMBER',
}

interface TableProps {
  columns: any;
  controlBar: React.ReactNode;
  currentPage?: any;
  emptyMessage: React.ReactChild;
  items: any[];
  loading?: boolean;
  onPageChange?: (page: number) => void;
  onSelect?: (selectedItem: any) => void;
  onClickItem: (id: string, typename?: string) => void;
  searchString?: string;
  selectedItems?: any[];
  selectionType?: RowSelectionType;
  stickyCol?: number;
  topBar: React.ReactNode;
  totalItemsCount?: number;
}

function TableHeader({ controlBar, topBar }) {
  return (
    <>
      <Row m="24px 0">
        <Col
          size="12"
          d="flex"
          justify="space-between"
          style={{ alignItems: 'center', padding: '0' }}
        >
          {topBar}
        </Col>
      </Row>
      {controlBar}
    </>
  );
}

function TableEmptyMessage({
  itemsCount,
  emptyMessage,
  searchString,
  loadingEmptyMessage,
}) {
  if (itemsCount) {
    return null;
  }

  return !loadingEmptyMessage ? (
    <NotFound text={searchString ? 'noMatch' : emptyMessage} />
  ) : null;
}

export default function Table(props: TableProps) {
  const {
    columns,
    controlBar,
    currentPage,
    emptyMessage,
    items,
    loading,
    onPageChange,
    onClickItem = false,
    searchString,
    selectionType,
    stickyCol,
    topBar,
    totalItemsCount,
  } = props;
  const pageSize: number = 20;

  let data;
  const status = 'status';

  if (items?.length) {
    data = items?.map((item, index) => {
      return (
        <tr
          key={index}
          style={{
            cursor: onClickItem ? 'pointer' : 'auto',
          }}
          className="table-item"
          onClick={() => {
            onClickItem ? onClickItem(item.id, item.__typeName) : {};
          }}
        >
          <td key={index}>
            {selectionType === RowSelectionType.NUMBER ? index + 1 : <Div />}
          </td>
          {columns.map(({ field }, indexCol) => {
            const statusColor = getStatusColor(item?.status?.toUpperCase());
            const isStatus = field === status;
            return (
              <td
                key={indexCol}
                className={indexCol === stickyCol ? 'sticky-col' : ''}
              >
                <Text
                  tag="div"
                  w="100%"
                  d="block"
                  textColor={isStatus ? statusColor : ''}
                  rounded="6px"
                  textTransform={isStatus && 'uppercase'}
                >
                  {typeof field === 'string' ? (
                    <Trans>{item[field] ? item[field] : ''}</Trans>
                  ) : (
                    item
                  )}
                </Text>
              </td>
            );
          })}
        </tr>
      );
    });
  } else {
    data = (
      <tr>
        <td></td>
        <td colSpan={columns.length} style={{ paddingTop: '140px' }}>
          <TableEmptyMessage
            loadingEmptyMessage={loading}
            emptyMessage={emptyMessage}
            itemsCount={items?.length}
            searchString={searchString}
          />
        </td>
      </tr>
    );
  }

  return (
    <>
      <TableHeader controlBar={controlBar} topBar={topBar} />
      <Div pos="relative" className="table-body" style={{ overflowX: 'auto' }}>
        {loading && <Spinner />}
        <table className="table">
          <thead>
            <tr>
              <th>{selectionType === RowSelectionType.NUMBER && '№'}</th>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className={index === stickyCol ? 'sticky-col' : ''}
                >
                  {column.title} {column.icon}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{data}</tbody>
        </table>
      </Div>
      <Div m={{ b: '30px' }} maxW="450px">
        <Pagination
          currentPage={currentPage}
          itemsCount={totalItemsCount ? totalItemsCount : 1}
          pageSize={pageSize}
          onPageChange={onPageChange ? onPageChange : () => {}}
        />
      </Div>
    </>
  );
}

export function isItemSelected(selectedItems: any[], item: any): boolean {
  return selectedItems && selectedItems.some((e) => e.id === item.id);
}
