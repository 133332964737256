/* External dependencies */
import { Col, Container, Row, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';
import SearchIcon from '../../../svg/SearchIcon';

interface NotFoundProps {
  text: string;
}

export default function NotFound({ text }: NotFoundProps) {
  const rowStyle = {
    height: '50vh',
    width: '100vw',
    maxWidth: '1200px',
    display: 'table-cell',
    verticalAlign: 'middle',
    overflowY: 'hidden',
    textAlign: 'center',
  };

  return (
    <Container>
      <Row style={rowStyle} flexDir="column">
        <Col>
          <SearchIcon width={60} height={60} />
        </Col>
        <Col>
          <Text
            textSize="36px"
            textWeight="700"
            textColor="input_placeholer_color"
            m={{ t: '40px' }}
          >
            <Trans>{text}</Trans>
          </Text>
        </Col>
      </Row>
    </Container>
  );
}
