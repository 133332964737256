// External depentensies
import React from 'react';

// Local dependensieseact
import { IconProps } from './types';

export default function CheckMarkIcon({
  width = 24,
  height = 24,
  fill = '#fff',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80323 4.59631L6.28056 7.90898L5.1919 6.51831C4.96523 6.22765 4.54523 6.17698 4.2559 6.40431C3.9659 6.63098 3.91456 7.05031 4.1419 7.33965L5.76256 9.41098C5.88923 9.57231 6.08256 9.66631 6.2879 9.66631H6.29256C6.49856 9.66565 6.69323 9.56831 6.8179 9.40365L9.8639 5.40365C10.0872 5.11031 10.0306 4.69298 9.73723 4.46965C9.44323 4.24631 9.0259 4.30365 8.80323 4.59631ZM7.00016 12.3333C4.0595 12.3333 1.66683 9.94065 1.66683 6.99998C1.66683 4.05931 4.0595 1.66665 7.00016 1.66665C9.94083 1.66665 12.3335 4.05931 12.3335 6.99998C12.3335 9.94065 9.94083 12.3333 7.00016 12.3333ZM7.00016 0.333313C3.31816 0.333313 0.333496 3.31865 0.333496 6.99998C0.333496 10.6813 3.31816 13.6666 7.00016 13.6666C10.6822 13.6666 13.6668 10.6813 13.6668 6.99998C13.6668 3.31865 10.6822 0.333313 7.00016 0.333313Z"
        fill={fill}
      />
    </svg>
  );
}
