/* External dependencies */
import { Div, Icon, Modal } from 'atomize';
import React, { useEffect } from 'react';

// Local dependencies
import './style.scss';

interface MainPopupProps {
  children?: any;
  minH?: any;
  minW?: any;
  maxW?: any;
  maxH?: any;
  onClose: () => void;
  title?: React.ReactElement;
}

export default function MainPopup(props: MainPopupProps) {
  const {
    children,
    minH = '300px',
    minW = '300px',
    maxW = '500px',
    maxH,
    onClose,
    title,
  } = props;

  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <Modal
      align="center"
      onClose={onClose}
      rounded="sm"
      w="100%"
      minH={minH}
      minW={{ xs: '300px', sm: minW }}
      maxW={maxW}
      maxH={maxH}
      p="10px"
      isOpen={true}
      className="popup"
    >
      <Div
        d="flex"
        align="center"
        justify={title ? 'space-between' : 'end'}
        p="16px"
      >
        {title}
        <Div
          bg="gray"
          hoverBg="gray_bg_hover"
          d="flex"
          align="center"
          justify="center"
          h="27px"
          w="27px"
          rounded="50%"
          cursor="pointer"
          onClick={onClose}
        >
          <Icon name="Cross" size="25px" color="grayTextInCard" />
        </Div>
      </Div>
      <Div
        border="1px solid"
        borderColor="border_color"
        style={{ overflowY: 'auto' }}
        minH={minH}
        maxH="70vh"
      >
        {children}
      </Div>
    </Modal>
  );
}
