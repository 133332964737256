// External dependencies
import { Div, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app/redux/store';
import { getUserRole } from '@shared/utils/roles';

export default function Administrator() {
  const { currentUser } = useSelector((state: RootState) => state?.login);
  const administratorsFirstname = currentUser?.given_name;
  const administratorsLastName = currentUser?.family_name;
  const administratorRole = getUserRole(currentUser);

  return (
    <Div d="none">
      <Div>
        <Text
          textSize="smallSize2"
          tag="h3"
        >{`${administratorsFirstname} ${administratorsLastName}`}</Text>
      </Div>
      <Div>
        <Text textColor="grey" tag="p">
          <Trans>{administratorRole}</Trans>
        </Text>
      </Div>
    </Div>
  );
}
