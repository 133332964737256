/* External dependencies */
import { Dropdown, Icon, Text } from 'atomize';
import React, { useState } from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';

/* Local dependencies */
import { defaultDropdownStyle } from '../form/FormStyles';
import DropdownItems from './DropdownItems';

export interface DropdownProps {
  disabled?: boolean;
  items: string[];
  name: string;
  onSelectAction: (e) => void;
  text?: string;
  width?: string;
  withoutTranslation?: boolean;
}

export default function CustomDropdown(props: DropdownProps) {
  const {
    disabled,
    items,
    onSelectAction,
    name,
    width = 'auto',
    withoutTranslation = false,
  } = props;
  const [isDropdownOpen, setOpenDropdown] = useState(false);

  function openDropdown() {
    setOpenDropdown(!isDropdownOpen);
  }

  function closeDropdown(e) {
    onSelectAction(e);

    setOpenDropdown(false);
  }

  return (
    <Dropdown
      {...defaultDropdownStyle}
      w={width}
      bg="input_bg"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      isOpen={isDropdownOpen}
      onClick={!disabled && openDropdown}
      menu={
        <DropdownItems
          withoutTranslation={withoutTranslation}
          items={items}
          onClick={closeDropdown}
        />
      }
      className="dropdown-custom"
      closeSuffix={
        <Icon
          name="DownArrow"
          color={disabled ? 'input_placeholer_color' : 'rgb(80, 82, 86)'}
          m={{ l: '1rem' }}
          size="18px"
        />
      }
    >
      <Text
        textColor={!disabled ? 'inherit' : 'input_placeholer_color'}
        textSize="12px"
        style={{ whiteSpace: 'nowrap' }}
        minW="80px"
      >
        <Trans>{name}</Trans>
      </Text>
    </Dropdown>
  );
}
