// External dependencies
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Helmet } from 'react-helmet';

// Local dependencies
import UserActions from '@entities/user-actions/ui/listUserActions/UserActions';
import UserLoggedIn from '@feautures/ui/userLoggedIn/UserLoggedIn';
import Layout from '@shared/ui/layout/WebLayout';
import { pagePermissions } from '../../../PagePermissions';

export default function UserActionPage({ data }) {
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = data;
  const { t } = useI18next();

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', t('adminPanelForUsers'))}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <UserLoggedIn roles={pagePermissions.userActions}>
        <UserActions />
      </UserLoggedIn>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
