/* External dependencies */
import { Div, Icon } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import Button, { ButtonTypeColor } from '../button';
import Popup from './Popup';
import PopupActions from './PopupActions';
import PopupContent from './PopupContent';

interface PrimaryPopupProps {
  loading?: boolean;
  title: string;
  onClose: () => void;
  onClick: () => void;
  btnText: string;
  cancelBtnText: string;
  children?: React.ReactNode;
  btnForActionTypeColor?: ButtonTypeColor;
}

export default function PrimaryPopup({
  loading,
  title,
  onClose,
  onClick,
  btnText,
  cancelBtnText,
  children,
  btnForActionTypeColor = 'primary',
}: PrimaryPopupProps) {
  return (
    <Popup title={title} onClose={loading ? null : onClose}>
      <PopupContent>{children}</PopupContent>
      <PopupActions>
        <Div d="flex" style={{ gap: '10px' }}>
          <Button
            textTransform="uppercase"
            rounded="5px"
            typeColor="white"
            disabled={loading}
            onClick={onClose}
            textColor="text_color"
          >
            <Trans>{cancelBtnText}</Trans>
          </Button>
          <Button
            textTransform="uppercase"
            rounded="5px"
            typeColor={btnForActionTypeColor}
            disabled={loading}
            onClick={onClick}
          >
            {loading ? (
              <>
                <Icon name="Loading" size="20px" />
                <Trans>{btnText}</Trans>
              </>
            ) : (
              <Trans>{btnText}</Trans>
            )}
          </Button>
        </Div>
      </PopupActions>
    </Popup>
  );
}
