// External dependencies
import { Div, Text } from 'atomize';
import { Link } from 'gatsby';
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app//redux/store';
import { logout } from '@entities/authentication/redux/actions';
import Button from '@shared/ui/button';
import { getUserRole } from '@shared/utils/roles';
import { getSiteLinks } from '../../../../PagePermissions';

export default function Nav() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.login);

  const links = getSiteLinks(getUserRole(currentUser));

  function logOutOfAdmin() {
    dispatch(logout());
  }

  return (
    <Div d="flex" style={{ gap: '20px' }} align="center">
      {links?.map(({ link, label }, index) => (
        <React.Fragment key={index}>
          <Link
            to={link}
            activeStyle={{ borderBottom: '1px solid black', color: 'black' }}
            style={{ color: '#808080' }}
          >
            <Text textWeight="bold">
              <Trans>{label}</Trans>
            </Text>
          </Link>
        </React.Fragment>
      ))}
      <Div>
        <Button typeColor="primary" iconSuffix="Logout" onClick={logOutOfAdmin}>
          <Trans>logout</Trans>
        </Button>
      </Div>
    </Div>
  );
}
