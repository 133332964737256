/* External dependencies */
import { Col, Div, Input, Label, Row, Text, Textarea } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';

/* Local dependencies */
import { defaultInputStyle, errorMessageStyle, labelStyle } from './FormStyles';

interface FormFieldProps {
  autoFocus?: boolean;
  autoComplete?: string;
  bg?: string;
  children?: React.ReactNode;
  colSize?: string;
  colSizeLabel?: string;
  disabled?: boolean;
  error?: any;
  fieldDescription?: string;
  inputName: string;
  inputStyle?: Object;
  inputType: string;
  inputValue: React.ReactNode;
  label?: React.ReactChild;
  minH?: string;
  onChange?: Function;
  onFocus?: Function;
  onClick?: () => void;
  placeholder?: React.ReactChild;
  required?: boolean;
  reference?: any;
  suffixIcon?: React.ReactChild;
  tabIndex?: string;
  prefix?: any;
}

export default function FormField({
  autoFocus,
  autoComplete,
  bg = 'input_bg', //default bg color
  children,
  colSize,
  colSizeLabel,
  disabled,
  error,
  fieldDescription,
  label,
  minH,
  inputName,
  inputStyle = defaultInputStyle,
  inputType,
  inputValue,
  onChange,
  onFocus,
  onClick,
  placeholder,
  required,
  reference,
  suffixIcon,
  tabIndex,
  prefix,
}: FormFieldProps) {
  const [isDivHeight, setDivHeight] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current?.clientHeight !== undefined) {
      setDivHeight(ref?.current?.clientHeight);
    }
  }, []);

  return (
    <>
      {label ? (
        <Col size={{ xs: '12', sm: colSizeLabel || '5' }}>
          <Row>
            <Col d="flex" justify="space-between" align="center">
              <Label {...labelStyle} style={{ whiteSpace: 'inherit' }}>
                {label}
              </Label>
            </Col>
          </Row>
        </Col>
      ) : null}
      <Col size={{ xs: '12', sm: colSize || '' }} w="100%">
        <Div pos="relative" onClick={onClick} ref={reference}>
          {inputType === 'Textarea' ? (
            <>
              <Div
                ref={ref}
                {...inputStyle}
                style={{ opacity: '0', height: 'auto' }}
                w="100%"
                pos="absolute"
              >
                {typeof inputValue === 'number' ? inputValue : inputValue || ''}
              </Div>
              <Textarea
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                bg={bg}
                disabled={disabled}
                id={inputName}
                name={inputName}
                placeholder={placeholder}
                value={
                  typeof inputValue === 'number' ? inputValue : inputValue || ''
                }
                onChange={onChange}
                onFocus={onFocus}
                required={required}
                minH={minH}
                tabIndex={tabIndex}
                {...inputStyle}
                suffix={suffixIcon}
                style={{
                  fontWeight: '600',
                  height: `${isDivHeight < 40 ? 42 : isDivHeight}px`,
                }}
                prefix={prefix}
              />
            </>
          ) : (
            <Input
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              bg={bg}
              disabled={disabled}
              id={inputName}
              type={inputType}
              name={inputName}
              placeholder={placeholder}
              value={
                typeof inputValue === 'number' ? inputValue : inputValue || ''
              }
              onChange={onChange}
              onFocus={onFocus}
              required={required}
              tabIndex={tabIndex}
              {...inputStyle}
              suffix={suffixIcon}
              prefix={prefix}
            />
          )}
          {children}
          <Text
            m={errorMessageStyle.m}
            textAlign="left"
            textSize="caption"
            textColor="backgroundTextColor"
          >
            {fieldDescription}
          </Text>
          <Text textSize="caption" {...errorMessageStyle} h="16px">
            <Trans>{error && error.code}</Trans>
          </Text>
        </Div>
      </Col>
    </>
  );
}
