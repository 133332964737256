// External depentensies
import React from 'react';

// Local dependensieseact
import { IconProps } from './types';

export default function RejectMarkIcon({
  width = 24,
  height = 24,
  fill = '#FFFFFF',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66683 7C1.66683 5.76934 2.09016 4.63867 2.79216 3.73534L10.2648 11.208C9.3615 11.9107 8.23083 12.3333 7.00016 12.3333C4.0595 12.3333 1.66683 9.94067 1.66683 7ZM12.3335 7C12.3335 8.23067 11.9102 9.36134 11.2082 10.2647L3.7355 2.792C4.63883 2.08934 5.7695 1.66667 7.00016 1.66667C9.94083 1.66667 12.3335 4.05934 12.3335 7ZM7.00016 0.333336C3.32416 0.333336 0.333496 3.324 0.333496 7C0.333496 10.676 3.32416 13.6667 7.00016 13.6667C10.6762 13.6667 13.6668 10.676 13.6668 7C13.6668 3.324 10.6762 0.333336 7.00016 0.333336Z"
        fill={fill}
      />
    </svg>
  );
}
