// External dependencies
import { Div, Icon, Row, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app//redux/store';
import { listUserActions } from '@entities/user-actions/redux/listUser-actions-redux/actions';
import {
  UpdateUserActionForManagerInput,
  updateUserActionForManagerRequest,
  updateUserActionForManagerReset,
  updateUserActionForManagerUpdateField,
} from '@entities/user-actions/redux/update-useractions-redux/actions';
import {
  ActionType,
  UserAction,
  UserActionStatus,
} from '@entities/user-actions/types';
import {
  isUserActionForExchangePoint,
  statusMessageMaxLength,
  tableColumnsForExchangePoints,
  tableColumnsForPurchase,
} from '@entities/user-actions/utils/helpers';
import { getStatusColor } from '@entities/users/lib/renderStatusColor';
import ManagerControlBarActions from '@feautures/ui/user-actions-controller/ManagerControlBarActions';
import MeducationManagerControlBarActions from '@feautures/ui/user-actions-controller/MeducationManagerControlBarActions';
import Header from '@shared/ui/admin/header/Header';
import FormField from '@shared/ui/form/FormField';
import ErrorPopup from '@shared/ui/popup/ErrorPopup';
import ImageZoomPopup from '@shared/ui/popup/ImageZoomPopup';
import PrimaryPopup from '@shared/ui/popup/PrimaryPopup';
import SuccessPopup from '@shared/ui/popup/SuccessPopup';
import Table, { RowSelectionType } from '@shared/ui/table/Table';
import { truncateText } from '@shared/utils/helpers';
import { getDateTime } from '@shared/utils/renderDate';
import { UserRoles, getUserRole } from '@shared/utils/roles';
import DefaultAvatarIcon from '@svg/DefaultAvatarIcon';
import UserActionDetailsPopup from './userActionDetails/UserActionDetailsPopup';

export default function UserActions() {
  const { currentUser } = useSelector((state: RootState) => state.login);
  const { currentPage, loading, total, userActions, filter } = useSelector(
    (state: RootState) => state.listUserActions,
  );
  const {
    isSuccess,
    error,
    loading: updateUserActionsLoading,
    userAction,
    statusMessage,
    statusMessageError,
  } = useSelector((state: RootState) => state.updateUserActionForManager);

  const [isConfirmationPopup, setConfirmationPopup] = useState(false);
  const [isButtonStatus, setButtonStatus] = useState('');
  const [clickedUserAction, setClickedUserAction] = useState(null);
  const [isImageZoomPopupOpen, setIsImageZoomPopupOpen] = useState(null);
  const [selectedUserAction, setSelectedUserAction] = useState(null);
  const dispatch = useDispatch();

  const updatedUserActionStatusColor = userAction?.status
    ? getStatusColor(userAction?.status?.toUpperCase())
    : 'default';

  const errorMessage =
    error && error?.code ? <Trans>{error?.code}</Trans> : error?.message;

  function onClickOpenConfirmationPopup(
    status: string,
    userAction: UserAction,
  ) {
    if (userAction && isUserActionForExchangePoint(userAction?.type)) {
      closeUserActionDetailPopup();
    }

    if (userAction && userAction?.statusMessage) {
      dispatch(
        updateUserActionForManagerUpdateField({
          statusMessage: userAction?.statusMessage,
        }),
      );
    }

    setButtonStatus(status);

    setClickedUserAction(userAction);

    setConfirmationPopup(true);
  }

  function continueUserActionsUpdate() {
    isButtonStatus === UserActionStatus.APPROVED
      ? updateUserActionsStatus(UserActionStatus.APPROVED)
      : updateUserActionsStatus(UserActionStatus.CANCELED);
  }

  function updateUserActionsStatus(status: UserActionStatus) {
    const updatedUserAction: UpdateUserActionForManagerInput = {
      id: clickedUserAction.id,
      status,
      eventDate: clickedUserAction.eventDate,
    };

    if (statusMessage) {
      updatedUserAction.statusMessage = statusMessage;
    }

    dispatch(updateUserActionForManagerRequest(updatedUserAction));
  }

  function onChangeInput(e) {
    const { name, value } = e.target;

    if (name === 'statusMessage' && value.length >= statusMessageMaxLength) {
      dispatch(
        updateUserActionForManagerUpdateField({
          statusMessage: value.slice(0, statusMessageMaxLength),
        }),
      );
      return;
    }

    dispatch(updateUserActionForManagerUpdateField({ [name]: value }));
  }

  function resetErrorSuccess() {
    setConfirmationPopup(false);
    dispatch(updateUserActionForManagerReset());
    setClickedUserAction(null);
  }

  function resetSuccessAndGetList() {
    resetErrorSuccess();
    dispatch(listUserActions('', currentPage, filter));
  }

  function getImage(user) {
    return (
      <>
        {user && user?.avatar ? (
          <img
            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
            src={user?.avatar}
            alt={user?.firstName}
          />
        ) : (
          <DefaultAvatarIcon width={40} />
        )}
      </>
    );
  }

  function openImageZoomPopup(url: string) {
    setIsImageZoomPopupOpen(url);
  }

  function closeImageZoomPopup() {
    setIsImageZoomPopupOpen(null);
  }

  function getName(action: any, fullNameSource: string) {
    const isActionExChangePoints = isUserActionForExchangePoint(action?.type);
    const firstName = action?.[fullNameSource]?.firstName
      ? action?.[fullNameSource]?.firstName
      : '';
    const lastName = action?.[fullNameSource]?.lastName
      ? action?.[fullNameSource]?.lastName
      : '';
    const patronymic = action?.[fullNameSource]?.patronymic
      ? action?.[fullNameSource]?.patronymic
      : '';

    return (
      <Div
        d="inline-block"
        cursor={isActionExChangePoints ? 'pointer' : 'default'}
        onClick={
          isActionExChangePoints ? () => onClickSelectUserAction(action) : null
        }
        className="user-action__user-name"
      >
        {firstName ? `${firstName} ${lastName} ${patronymic}` : action?.userId}
      </Div>
    );
  }

  function onClickSelectUserAction(action) {
    setSelectedUserAction(action);
  }

  function closeUserActionDetailPopup() {
    setSelectedUserAction(null);
  }

  function checkImage(url: string) {
    return (
      <Div d="inline-block" p="10px" rounded="circle" cursor="pointer">
        <Div
          d="flex"
          justify="center"
          align="center"
          onClick={() => openImageZoomPopup(url)}
        >
          {url ? (
            <img
              style={{ width: '40px', height: '40px', borderRadius: '50%' }}
              src={url}
              alt="check"
            />
          ) : (
            <Icon name="Camera" size="40px" />
          )}
        </Div>
      </Div>
    );
  }

  function actionUser(action) {
    return (
      <Div d="flex" style={{ gap: '10px' }} justify="center">
        {(filter?.status === UserActionStatus.IN_REVIEW ||
          filter?.status === UserActionStatus.CANCELED) && (
          <Div
            bg="#D3F3CE"
            hoverBg="#b7d3b3"
            p="10px"
            cursor="pointer"
            rounded="md"
            onClick={() =>
              onClickOpenConfirmationPopup(UserActionStatus.APPROVED, action)
            }
            className="action-button_approve"
          >
            <Div d="flex">
              <Icon
                name="Checked"
                color="#2D8320"
                hoverColor="#276f1c"
                size="25px"
              />
            </Div>
          </Div>
        )}

        {(filter?.status === UserActionStatus.IN_REVIEW ||
          filter?.status === UserActionStatus.APPROVED) && (
          <Div
            bg="#FFD6D6"
            hoverBg="#e7b5b5"
            p="10px"
            cursor="pointer"
            rounded="md"
            onClick={() =>
              onClickOpenConfirmationPopup(UserActionStatus.CANCELED, action)
            }
            className="action-button_cancele"
          >
            <Div d="flex">
              <Icon name="Cross" color="red" hoverColor="#b40000" size="25px" />
            </Div>
          </Div>
        )}
      </Div>
    );
  }

  function handleCloseConfirmationPopup() {
    setConfirmationPopup(false);

    if (statusMessage) {
      dispatch(updateUserActionForManagerUpdateField({ statusMessage: '' }));
    }
  }

  try {
    return (
      <Div m="0 auto" className="container">
        <Header />
        <Div p={{ t: '80px' }}>
          <Table
            items={userActions?.map((action) => ({
              id: action?.user?.id,
              userAvatar: getImage(action?.user),
              firstName: getName(action, 'user'),
              fullName: getName(action, 'data'),
              phone: action?.phone,
              eventPaymentDate: getDateTime(action?.eventDate),
              status: action?.status,
              statusMessage: truncateText(action?.statusMessage),
              check: checkImage(action?.data?.receiptFile),
              coursePrice: `${action?.data?.parent?.coursePrice?.value} ${action?.data?.parent?.coursePrice?.currency}`,
              action: actionUser(action),
              destinationAddress: action?.data?.destinationAddress,
              totalPoints: `${action?.data?.totalPoints}`,
            }))}
            columns={getTableColumnsForUserActions(filter?.actionType)}
            currentPage={1}
            emptyMessage="noUserActions"
            controlBar={getControlBar()}
            loading={loading}
            onPageChange={() => {}}
            stickyCol={1}
            searchString={''}
            selectionType={RowSelectionType.SINGLE}
            topBar={''}
            totalItemsCount={total}
          />
        </Div>
        {isConfirmationPopup && !isSuccess && !error && (
          <PrimaryPopup
            title={isButtonStatus === 'APPROVED' ? 'approve' : 'reject'}
            btnText={isButtonStatus === 'APPROVED' ? 'approve' : 'reject'}
            cancelBtnText="cancel"
            onClick={continueUserActionsUpdate}
            onClose={handleCloseConfirmationPopup}
            loading={updateUserActionsLoading}
            btnForActionTypeColor={
              isButtonStatus === 'APPROVED' ? 'success' : 'primary'
            }
          >
            <Div
              maxW="350px"
              textSize="display1"
              textColor="warning_color"
              textWeight="500"
            >
              <Trans>youWantToUpdateUserActionsStatus</Trans>

              <Text m={{ t: '1rem' }} textColor="text_color">
                <Trans>MedcheckUserWithFirstUpperLetter</Trans>:
              </Text>
              <Text textColor="text_color">
                {clickedUserAction?.user?.firstName
                  ? `${clickedUserAction?.user?.firstName} ${clickedUserAction?.user?.lastName} ${clickedUserAction?.user?.patronymic}`
                  : clickedUserAction?.id}
              </Text>
              <Row p={{ t: '1rem' }}>
                <FormField
                  bg="basic_border_color"
                  inputType="Textarea"
                  label={<Trans>statusMessage</Trans>}
                  inputName="statusMessage"
                  inputValue={statusMessage}
                  onChange={onChangeInput}
                  error={statusMessageError}
                />
              </Row>
            </Div>
          </PrimaryPopup>
        )}
        {isSuccess && (
          <SuccessPopup
            onSubmit={() => resetSuccessAndGetList()}
            submitText="close"
            title="successfullyChangedStatus"
          >
            <Div>
              {clickedUserAction?.user?.firstName && (
                <>
                  <Text textColor="primary">
                    <Trans>MedcheckUserWithFirstUpperLetter</Trans>:
                  </Text>
                  <Text textColor="primary">
                    {`${clickedUserAction?.user?.firstName} ${clickedUserAction?.user?.lastName} ${clickedUserAction?.user?.patronymic}`}
                  </Text>
                </>
              )}
              <Div d="flex" m={{ t: '15px' }}>
                <Text textColor="primary" m={{ r: '5px' }}>
                  <Trans>status</Trans>:
                </Text>{' '}
                <Text textColor={updatedUserActionStatusColor}>
                  <Trans>{userAction?.status}</Trans>
                </Text>
              </Div>
              {userAction?.statusMessage && (
                <Div m={{ t: '15px' }}>
                  <Text textColor="primary" m={{ r: '5px' }}>
                    <Trans>statusMessage</Trans>:
                  </Text>{' '}
                  <Text textColor={updatedUserActionStatusColor}>
                    <Trans>{userAction?.statusMessage}</Trans>
                  </Text>
                </Div>
              )}
            </Div>
          </SuccessPopup>
        )}
        {error && (
          <ErrorPopup
            onSubmit={() => resetErrorSuccess()}
            submitText="close"
            title="errorOccurred"
          >
            <Trans>{errorMessage}</Trans>
          </ErrorPopup>
        )}

        {isImageZoomPopupOpen && (
          <ImageZoomPopup
            image={isImageZoomPopupOpen ? isImageZoomPopupOpen : ''}
            isOpen={isImageZoomPopupOpen ? true : false}
            onClose={closeImageZoomPopup}
          />
        )}

        {selectedUserAction && (
          <UserActionDetailsPopup
            onClose={closeUserActionDetailPopup}
            openConfirmationPopup={onClickOpenConfirmationPopup}
            action={selectedUserAction}
          />
        )}
      </Div>
    );
  } catch (error) {
    return (
      <Div className="loading">
        <Div className="loading__icon"></Div>
      </Div>
    );
  }

  function getControlBar() {
    const userRole = getUserRole(currentUser);

    switch (true) {
      case userRole.includes(UserRoles.MANAGER):
        return <ManagerControlBarActions />;

      case userRole.includes(UserRoles.MEDUCATION_MANAGER):
        return <MeducationManagerControlBarActions />;

      case null: {
        throw new Error('client has not set up yet');
      }
      default:
        throw new Error('Permission denied');
    }
  }

  function getTableColumnsForUserActions(actionType: ActionType) {
    switch (actionType) {
      case ActionType.EXCHANGEPOINTS:
        return tableColumnsForExchangePoints;

      default:
        return tableColumnsForPurchase;
    }
  }
}
