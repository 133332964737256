// External dependencies
import { Div } from 'atomize';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Local dependencies
import MedcheckLogoWithSmile from '@svg/MedcheckLogoWithSmile';
import Administrator from '../administrator/Administrator';
import Nav from './Nav';

export default function Header() {
  return (
    <Div d="flex" justify="space-between" m={{ t: '15px' }} maxW="1500px">
      <Div w="200px">
        <Link to="/admin/users">
          <MedcheckLogoWithSmile />
        </Link>
      </Div>
      <Nav />
      <Administrator />
    </Div>
  );
}
