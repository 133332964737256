/* External dependencies */
import { Button, Col, Div, Text, Row } from 'atomize';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Trans } from 'react-i18next';

// Local dependencies
import MainPopup from '@shared/ui/popup/MainPopup';
import { getWordForm } from '@entities/user-actions/utils/helpers';
import CheckMarkIcon from '@svg/CheckMarkIcon';
import RejectMarkIcon from '@svg/RejectMarkIcon';
import { UserAction, UserActionStatus } from '@entities/user-actions/types';
import { prizes } from '@entities/user-actions/utils/mockData';
import { getDateTime } from '@shared/utils/renderDate';

interface UserActionDetailsPopupProps {
  action: UserAction;
  onClose: () => void;
  openConfirmationPopup: (status: string, userAction: UserAction) => void;
}

export default function UserActionDetailsPopup(
  props: UserActionDetailsPopupProps,
) {
  const { action, onClose, openConfirmationPopup } = props;
  const { prizes, destinationAddress, phone, totalPoints } = action?.data;
  const { language } = useI18next();
  const borderTopStyle = { borderTop: '1px solid #EDF1F7' };

  return (
    <MainPopup
      title={
        <Text textSize="20px" textWeight="700">
          <Trans>requestDetails</Trans>
        </Text>
      }
      onClose={onClose}
      maxH="400"
    >
      <>
        <Div p="16px" style={borderTopStyle}>
          <Text textWeight="600">{getDateTime(action.eventDate)}</Text>
        </Div>
        <Div style={borderTopStyle}>
          {prizes.map((prize) => (
            <Div
              key={prize?.id}
              style={borderTopStyle}
              d="flex"
              justify="space-between"
              p="16px"
            >
              <Div d="flex" style={{ gap: '16px' }}>
                <Div w="88px" h="88px">
                  <img
                    src={prize?.avatar[0]?.url || ''}
                    alt={prize?.name}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      objectPosition: 'bottom',
                    }}
                  />
                </Div>
                <Text textSize="14px">{prize?.name}</Text>
              </Div>
              <Text textSize="14px" textWeight="600">
                {prize?.requiredPoints}{' '}
                <Trans>{getWordForm(prize?.requiredPoints, language)}</Trans>
              </Text>
            </Div>
          ))}
        </Div>
        <Div p="16px" style={borderTopStyle} d="flex" justify="space-between">
          <Text textSize="16px" textWeight="600">
            <Trans>total</Trans>
          </Text>

          <Text textSize="14px" textWeight="600">
            {totalPoints} <Trans>{getWordForm(70, language)}</Trans>
          </Text>
        </Div>
        <Div p="16px" style={borderTopStyle}>
          <Text textSize="20px" textWeight="700" m={{ b: '16px' }}>
            <Trans>phone</Trans>
          </Text>
          <Text textSize="14px" style={{ lineHeight: '20px' }}>
            {phone}
          </Text>
        </Div>
        <Div p="16px" style={borderTopStyle}>
          <Text textSize="20px" textWeight="700" m={{ b: '16px' }}>
            <Trans>deliveryAddress</Trans>
          </Text>
          <Text textSize="14px" style={{ lineHeight: '20px' }}>
            {destinationAddress}
          </Text>
        </Div>
        <Div p="16px" style={borderTopStyle}>
          <Row>
            <Col size={{ xs: 12, md: '6' }}>
              <Button
                bg="bg_approve"
                hoverBg="bg_approve_hover"
                m={{ b: { xs: '1rem', md: '0' } }}
                onClick={() =>
                  openConfirmationPopup(UserActionStatus.APPROVED, action)
                }
                className="user-action-details_complete-btn"
              >
                <CheckMarkIcon width={16} height={16} />
                <Text
                  textTransform="uppercase"
                  textSize="12px"
                  textWeight="700"
                  m={{ l: '8px' }}
                >
                  <Trans>complete</Trans>
                </Text>
              </Button>
            </Col>
            <Col size={{ xs: 12, md: '6' }}>
              <Button
                bg="btn_danger_bg"
                hoverBg="btn_hover_bg_danger"
                onClick={() =>
                  openConfirmationPopup(UserActionStatus.CANCELED, action)
                }
                className="user-action-details_reject-btn"
              >
                <RejectMarkIcon width={16} height={16} />
                <Text
                  textTransform="uppercase"
                  textSize="12px"
                  textWeight="700"
                  m={{ l: '8px' }}
                >
                  <Trans>reject</Trans>
                </Text>
              </Button>
            </Col>
          </Row>
        </Div>
      </>
    </MainPopup>
  );
}
