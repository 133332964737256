/* External dependencies */
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import Button from '../button';
import Popup from './Popup';
import PopupActions from './PopupActions';
import PopupContent from './PopupContent';

interface ErrorPopupProps {
  children: any;
  onSubmit: () => void;
  submitText: string;
  title: string;
  maxW?: string;
}

export default function ErrorPopup({
  children,
  onSubmit,
  submitText,
  title,
  maxW = '400px',
}: ErrorPopupProps) {
  return (
    <Popup title={title} maxW={maxW}>
      <PopupContent>{children}</PopupContent>
      <PopupActions>
        <Button typeColor="primary" onClick={onSubmit}>
          <Trans>{submitText}</Trans>
        </Button>
      </PopupActions>
    </Popup>
  );
}
