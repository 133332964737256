/* External dependencies */
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */
import Button from '../button';
import Popup from './Popup';
import PopupActions from './PopupActions';
import PopupContent from './PopupContent';

interface SuccessPopupProps {
  children: React.ReactNode;
  onSubmit: () => void;
  submitText: string;
  title: string;
}

export default function SuccessPopup({
  children,
  onSubmit,
  submitText,
  title,
}: SuccessPopupProps) {
  return (
    <Popup headerColor="btn_success_bg" title={title}>
      <PopupContent>{children}</PopupContent>
      <PopupActions>
        <Button rounded="5px" typeColor="success" onClick={onSubmit}>
          <Trans>{submitText}</Trans>
        </Button>
      </PopupActions>
    </Popup>
  );
}
